<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer" />
      <div slot="content">
             <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>
        <div class="pt-1">
          <div class="row">
            <div class="col-md-3 col-1" v-if="windowWidth > 767">

              <!-- <img src="../../../assets/images/back2.png" style="cursor:pointer"> -->

            </div>
            <div class="col-md-6 col-12 text-center">
              <div style="min-width:100%; display:flex; justify-content:center">
                 <div
                  class="tab-div"
                  style="display:flex; justify-content:center;  align-items:center; max-width:400px; min-height:60px"
                >
                  <div style="flex-basis:50%; " class="pb-2 point-cursor" @click="gotoVestLock">
                    <p>Vestlock</p>
                  </div>
                  <div
                    style="flex-basis:50%; border-bottom:1px solid #0094FF"
                    class="pb-2 point-cursor" >
                    <p>Vestlock Portfolio</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 text-right" v-if="windowWidth > 767"></div>
          </div>
        </div>
        <div class="container">
          <div class="vest-container">
            <div style="margin:0 auto" class="text-center" v-if="vestPortfolio.length < 1">
              <h5>Sorry you do not have any Vestlock investment</h5>
              <button class="vest-btn-2 mt-4" @click="sendToAmountView" style="min-width:100%">
                <b>Invest Now</b>
              </button>
            </div>
            <div style="min-width:100%; margin:0 auto" v-if="vestPortfolio.length > 0">
              <div style="display:flex;">
                <div style="flex-basis:50%">
                  <p v-if="vestPortfolio.length < 2">{{vestPortfolio.length}} lock worth     <span>&#8358;</span> {{formatAmount(totalVestedAmount)}}</p>
                  <p v-else>{{vestPortfolio.length}} locks worth     <span>&#8358;</span> {{formatAmount(totalVestedAmount)}}</p>
                </div>

                <div class="text-right" style="flex-basis:50%"  @click="viewPortfolio">
                  <p
                    style="line-height:20px; text-decoration:underline"
                    class="point-cursor"
                  >view others</p>
                </div>
              </div>
            </div>
            <div style="min-width:100%; margin:0 auto" v-for="vest in vestPortfolio" :key="vest.id">
              <div
                class="vest-btn mt-2"
                style="margin:0 auto; min-width:100%; border:1px solid #fff"
              >
                <div class="first-div">
                  <h6 style="color:#000">
                    <b>
                      <span>&#8358;</span>
                      {{formatAmount(vest.amount)}}
                    </b>
                  </h6>
                  <p
                    style="color:#828282"
                    class="pt-2"
                  >{{ vest.opens_at | formatDate}} - {{ vest.matures_at | formatDate}}</p>
                </div>

                <div class="text-center sec-div"
                  style="background:#FAFAFA; display:flex; align-items:center; justify-content:center; min-height:30px; padding:10px">

                <div class="display-div">
                        <div class="text-left">
                            <p>&#8358;</p>
                        </div>
                        <div>
                            <p class="text-right"> {{formatAmount(vest.totalReturn)}} </p>
                        </div>

                </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";

export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {}
    };
  },
  methods: {
    sendToMarketPlace() {
      this.$router.push("/market");
    },
    sendToAmountView() {
      this.$store.commit("auth/saveVestRouteVisited", "/vest/amount");
      this.$router.push("/vest/amount");
    },
    gotoVestLock() {
      this.$store.commit(
        "auth/saveVestRouteVisited",
        this.vestRouteVisitedBeforePortfolio
      );
      this.$router.push(this.vestRouteVisitedBeforePortfolio);
    },
    viewPortfolio(){

      
      this.$router.push('/portfolio');
    }
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
      vestRouteVisited: state => state.auth.vestRouteVisited,
      vestRouteVisitedBeforePortfolio: state =>
        state.auth.vestRouteVisitedBeforePortfolio,
      vestPortfolio: state => state.market.vestPortfolio,
      totalVestedAmount: state => state.market.totalVestedAmount
    }),

    returnAmountOfPenalty() {
      return this.penaltyAmount;
    }
  },
  mounted() {
    let heading = {
      main: this.tran.vest_lock
    };

    this.$store.commit("market/savePageHeading", heading);
    this.$store.dispatch("market/getVestPortfolio");
  },

  beforeCreate() {
    this.$store.dispatch("market/getPortfolio");
    this.$store.commit("market/checkSearchObject", "portfolio");
  },

  beforeDestroy() {
    this.$store.commit("market/startPreloader");

    this.$store.commit("market/startIsFetching");
    this.$store.commit("market/removePageHeading");
  }
};
</script>

<style scoped>


</style>

<template>
<div>
    <VestPort />
</div>
</template>

<script>
import VestPort from '../../components/dashboard/vestfolder/VestPortfolio.vue'
export default {
    components: {
        VestPort
    }
}
</script>

